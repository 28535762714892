import { useCallback, useContext, useMemo } from "react"
import { useAuth } from "../../auth/provider"
import { PrefsContext } from '../../prefs/PrefsContext'
import { ReportDefinition, ReportRun } from "../../api/types"
import { CloserFunc, NoParamsFunc } from "../../types/types"
import { formatEpoch } from "../../time/timeRanges"
import { createReportRun, getFileServerFileByCustomer } from "../../api/endpoints"
import { runStatusLabel } from "./schedule"
import ModalBasic, { ButtonConfig } from "../controls/modals/ModalBasic"

import './ModalRunDetails.css'

interface ModalRunDetailsProps {
  closer: CloserFunc
  run: ReportRun
  def: ReportDefinition
  requestReloadFunc: NoParamsFunc
}

const downloadFile = ((run: ReportRun, file: string) => {
  getFileServerFileByCustomer(run.customerID, `reports/${run.id}/${file}`)
  .then((resp) => {
    window.location.href = resp.url
  })
})

export function ModalRunDetails(props: ModalRunDetailsProps) {

  const { isCustomerEditor } = useContext(PrefsContext)
  const { username } = useAuth()

  const { run, def, requestReloadFunc, closer } = props

  const canOpenEditor = useMemo((): boolean => {
    return isCustomerEditor || username === def.createdBy
  }, [def, isCustomerEditor, username])

  const generateAgain = useCallback((): void => {
    createReportRun(run.customerID, {reportDefinitionID: run.reportDefinitionID} as ReportRun)
    .then(() => {
      requestReloadFunc()
      closer()
    })

  }, [run, requestReloadFunc, closer])

  const title = useMemo((): string => {
    return !def.description ? 'Untitled Report' : def.description
  }, [def])

  const buttons = useMemo((): ButtonConfig[] => {
    const out = [] as ButtonConfig[]
    out.push(
      {
        title: 'Generate Again',
        onClick: generateAgain,
        className: 'me-3'
      } as ButtonConfig
    )
    if (!canOpenEditor) {
      return out
    }
    out.push(
      {
        title: 'Edit Report Settings',
        onClick: () => { window.location.pathname = `/reports/settings/${def.customerID}/${def.id}`}
      } as ButtonConfig
    )
    return out
  }, [def, generateAgain, canOpenEditor])

  return <ModalBasic
    headerText={title}
    closer={closer}
    useXCloser={true}
    sz="sm"
    buttonConfigs={buttons}
  >
    {run &&
      <div className="report-run-detail-body">
        <div className="report-definition-detail-row">
          <span className="me-2"><b>Time:</b></span><span>{formatEpoch(run.ts || 0)} </span>
        </div>
        <div className="report-definition-detail-row">
          <span className="me-2"><b>Status:</b></span><span>{runStatusLabel(run)} </span>
        </div>
        {run.files && run.files.length > 0 && <>
        <div className="report-definition-detail-row">
          <span className="me-2"><b>Generated Files:</b></span>
        </div>
        <div className="report-file-list ms-3">
          {run.files.map((file) => {
            return (
              <div className="report-file-list-row" key={file}>
                <label className="link-normal" onClick={() => {downloadFile(run, file)}}>{file}</label>
              </div>
            )
          })}
        </div>
        </>}
        {(!run.files || run.files.length === 0) &&
          <div className="report-definition-detail-row mt-4">
            <span className="me-2"><b>No Files Were Generated.</b></span>
          </div>
        }
      </div>
    }
  </ModalBasic>
}