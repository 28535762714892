import { useState, useContext, useEffect } from 'react'
import { PrefsContext } from '../../prefs/PrefsContext'

import { ReportDefinition } from '../../api/types'
import { getReportDefinitionsByCustomer } from '../../api/endpoints'
import { ModalGenerateReport } from './ModalGenerateReport'
import Pillbox from '../controls/Pillbox/Pillbox'
import { Pill } from '../controls/Pillbox/pills'
import ButtonBasic from '../controls/Buttons/ButtonBasic'
import { RecentReportsPane } from './RecentReportsPane'
import { AllReportsPane } from './AllReportsPane'

import './ReportsPage.css'
import { useNavigate, useParams } from 'react-router-dom'

export function ReportsPage() {

  const { section } = useParams()
  const [isGenerateFormVisible, setIsGenerateFormVisible] = useState(false)
  const [tab, setTab] = useState(section === 'all' || section === 'settings' ? 1 : 0)
  const [reportDefs, setReportDefs] = useState([] as ReportDefinition[])
  const [reloadRequested, setReloadRequested] = useState(Date.now())
  const { customer } = useContext(PrefsContext)
  const nav = useNavigate()

  const closer = () => {
    setIsGenerateFormVisible(false)
    requestReload()
  }

  const requestReload = (): void => {
    setReloadRequested(Date.now())
  }

  useEffect(() => {
    const x = {
      c: customer,
      r: reloadRequested
    }
    getReportDefinitionsByCustomer(x.c)
    .then((resp: ReportDefinition[]) => {
      setReportDefs(resp)
    })
  }, [customer, reloadRequested])

  const tabChanged = (tab: number) => {
    if (tab === 1) {
      nav('/reports/all', {replace: true})
    } else {
      nav('/reports/recent', {replace: true})
    }
    setTab(tab)
  }

  return <div className="reports-page">
    <div className="reports-page-nav">
    <Pillbox
      pills={[
        {caption: 'Recently Generated Reports', value: 'recent'},
        {caption: 'All Reports', value: 'all'}
      ] as Pill[]}
      tall={true}
      className="reports-page-pillbox"
      defaultValue={tab}
      onClick={tabChanged}
    />
    <ButtonBasic label="+ New Report" onClick={() => {setIsGenerateFormVisible(true)}} />
    </div>

    {tab !== 1 && <RecentReportsPane defs={reportDefs} />}
    {tab === 1 && <AllReportsPane defs={reportDefs} requestReloadFunc={requestReload}  />}

    {isGenerateFormVisible && <ModalGenerateReport closer={closer} />}

  </div>
}
