import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useAuth } from "../../auth/provider"
import { useNavigate, useParams } from "react-router-dom"
import { PrefsContext } from '../../prefs/PrefsContext'
import { ReportDefinition, ReportRun } from "../../api/types"
import { headerColumn, row, rowCell } from "../controls/Tables/tables"
import BasicTable from "../controls/Tables/BasicTable"
import { ago } from "../../time/relativeTime"
import { statusLabel } from "./schedule"
import { ModalGenerateReport } from "./ModalGenerateReport"
import { NoParamsFunc } from "../../types/types"
import { createReportRun } from "../../api/endpoints"

import './AllReportsPane.css'

interface AllReportsPaneProps {
  defs?: ReportDefinition[]
  requestReloadFunc?: NoParamsFunc
}

const runStatusClass = (input: string): string => {
  if (input === 'success') {
    return 'report-run-success'
  }
  if (input === 'fail') {
    return 'report-run-failed'
  }
  return ''
}

const getDef = (id: string, defs?: ReportDefinition[]): ReportDefinition | undefined => {
  if (!id || !defs || defs.length === 0) {
    return undefined
  }
  for (const def of defs) {
    if (def.id === id) {
      return def
    }
  }
  return undefined
}

export function AllReportsPane(props: AllReportsPaneProps) {
  const { section, customerId, elementId } = useParams()
  const { defs, requestReloadFunc } = props
  const [def, setDef] = useState( customerId && elementId && section === 'settings' ? getDef(elementId, defs) : undefined as ReportDefinition | undefined)
  const [isEditorOpen, setIsEditorOpen] = useState(def ? true : false)
  const { customer, customerAccess, isCustomerEditor } = useContext(PrefsContext)
  const { username } = useAuth()
  const nav = useNavigate()

  const hasRunningReports = useMemo((): boolean => {
    if (!defs || defs.length === 0) {
      return false
    }
    for (const def of defs) {
      if (def && (['new', 'running'].includes(def.lastRanStatus || ''))) {
        return true
      }
    }
    return false
  }, [defs])

  useEffect(() => {
    const interval = setInterval(() => {
      if (hasRunningReports && requestReloadFunc) { requestReloadFunc() }
    }, 3000);
    return () => clearInterval(interval);
  }, [hasRunningReports, requestReloadFunc]);

  useEffect(() => {
    if (customerId && elementId && section === 'settings') {
      const d = getDef(elementId, defs)
      if (d) {
        setDef(d)
        setIsEditorOpen(true)
      }
    }
  }, [defs, customerId, elementId, section])

  const startRun = useCallback((def: ReportDefinition): void => {
    createReportRun(def.customerID, {reportDefinitionID: def.id} as ReportRun)
    .then(() => {
      if (requestReloadFunc) {
        requestReloadFunc()
      }
    })
  }, [requestReloadFunc])

  const customerDN = useMemo((): string => {
    if (!customerAccess || !customerAccess.displayName) {
      return 'Unknown Organization'
    }
    return customerAccess.displayName
  }, [customerAccess])

  const editorClosed = () => {
    if (requestReloadFunc) {
      requestReloadFunc()
    }
    nav('/reports/all', {replace: true})
    setIsEditorOpen(false)
  }

  const canOpenEditor = useCallback((def: ReportDefinition): boolean => {
    return isCustomerEditor || username === def.createdBy
  }, [isCustomerEditor, username])

  const openEditor = useCallback((rd: ReportDefinition) => {
    setDef(rd)
    nav(`/reports/settings/${customer}/${rd.id}`, {replace: true})
    setIsEditorOpen(true)
  }, [customer, nav])

  const cols = [
    { text: 'Report Name' },
    { text: 'Last Generated' },
    { text: 'Last Status' },
    { text: 'Format' },
    { text: 'Email?' },
    { text: 'Scheduled?' },
    { text: '' }
  ] as headerColumn[]

  const rows = useMemo((): row[] => {
    const out = [] as row[]
    if (!defs || defs.length === 0) {
      return out
    }
    for (const def of defs) {
      out.push({
        cells: [
          {
            value: def.description || '-',
            onClick: canOpenEditor(def) ? () => {openEditor(def)} : undefined,
          },
          {
            value: def.lastRan ? ago(def.lastRan) : '-',
          },
          {
            value: def.lastRanStatus ? statusLabel(def.lastRanStatus) : '-',
            className: runStatusClass(def.lastRanStatus || '')
          },
          {
            value: (def.reportFormat || '-').toUpperCase(),
          },
          {
            value: def.emailEnabled ? '✓' : '-',
            className: 'centered'
          },
          {
            value: ['weekly', 'daily', 'monthly'].includes(def.entryType) ? '✓' : '-',
            className: 'centered'
          },
          {
            value: 'Generate',
            onClick: () => {startRun(def)},
            isButton: true,
            buttonSize: 'sm',
            buttonOutline: true
          }
        ] as rowCell
      } as row)

    }
    return out
  }, [defs, openEditor, startRun, canOpenEditor])

  return <div className="reports-all-definitions-list">
    <BasicTable
      cols={cols}
      rows={rows}
      noDataMessage={`${customerDN} has no reports. Press '+ New Report' to generate one.`}
    />
    {isEditorOpen && def &&
      <ModalGenerateReport
        closer={editorClosed}
        reportDefinition={def}
      />
    }
</div>

}