import { useState, useContext, useEffect, useMemo, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { PrefsContext } from '../../prefs/PrefsContext'
import { ReportDefinition, ReportRun, ReportDefinitionLookup } from '../../api/types'
import { getReportRunsByCustomer } from '../../api/endpoints'
import { headerColumn, row, rowCell } from '../controls/Tables/tables'
import { ago } from '../../time/relativeTime'
import { runStatusLabel } from './schedule'
import { ModalRunDetails } from './ModalRunDetails'
import BasicTable from '../controls/Tables/BasicTable'

import './RecentReportsPane.css'

interface RecentReportsPaneProps {
  defs?: ReportDefinition[]
}

const runStatusClass = (run: ReportRun): string => {
  if (run.status === 'success') {
    return 'report-run-success'
  }
  if (run.status === 'fail') {
    return 'report-run-failed'
  }
  return ''
}

const getRun = (id: string, runs?: ReportRun[]): ReportRun | undefined => {
  if (!id || !runs || runs.length === 0) {
    return undefined
  }
  for (const run of runs) {
    if (run.id === id) {
      return run
    }
  }
  return undefined
}

export function RecentReportsPane(props: RecentReportsPaneProps) {
  const { section, customerId, elementId } = useParams()

  const [isLoading, setIsLoading] = useState(true)
  const [recentRuns, setRecentRuns] = useState([] as ReportRun[])
  const [isRunModalVisible, setIsRunModalVisible] = useState(false)
  const [run, setRun] = useState(undefined as ReportRun | undefined)
  const [reloadRequested, setReloadRequested] = useState(Date.now())
  const { customer, customerAccess } = useContext(PrefsContext)
  const { defs } = props
  const nav = useNavigate()

  const requestReload = useCallback((): void => {
    setReloadRequested(Date.now())
  }, [setReloadRequested])

  useEffect(() => {
    if (customerId && elementId && section === 'generated') {
      const r = getRun(elementId, recentRuns)
      if (r) {
        setRun(r)
        setIsRunModalVisible(true)
      }
    }
  }, [recentRuns, customerId, elementId, section])

  const customerDN = useMemo((): string => {
    if (!customerAccess || !customerAccess.displayName) {
      return 'Unknown Organization'
    }
    return customerAccess.displayName
  }, [customerAccess])

  const runModalClosed = () => {
    nav('/reports/recent', {replace: true})
    setIsRunModalVisible(false)
  }

  const openRunModal = useCallback((run?: ReportRun) => {
    if (!run) {
      return
    }
    setRun(run)
    nav(`/reports/generated/${customer}/${run.id}`, {replace: true})
    setIsRunModalVisible(true)
  }, [setRun, setIsRunModalVisible, nav, customer])

  const cols = [
    { text: 'Report Name' },
    { text: 'Time' },
    { text: 'Format' },
    { text: 'Status' },
    { text: '# Files' },
  ] as headerColumn[]


  const hasRunningReports = useMemo((): boolean => {
    if (!recentRuns || recentRuns.length === 0) {
      return false
    }
    for (const run of recentRuns) {
      if (run && (['new', 'running'].includes(run.status || ''))) {
        return true
      }
    }
      return false
  }, [recentRuns])

  useEffect(() => {
    const interval = setInterval(() => {
      if (hasRunningReports) { requestReload() }
    }, 3000);
    return () => clearInterval(interval);
  }, [hasRunningReports, requestReload]);

  const defsLookup = useMemo((): ReportDefinitionLookup => {
    const out = {} as ReportDefinitionLookup
    if (!defs) {
      return out
    }
    for (const def of defs) {
      out[def.id] = def
    }
    return out
  }, [defs])

  useEffect(() => {
    setIsLoading(true)
    getReportRunsByCustomer(customer)
    .then((resp: ReportRun[]) => {
      if (resp && resp.length > 0) {
        setRecentRuns(resp)
      }
    })
    .finally(() => {
      setIsLoading(false)
    })
  }, [customer, reloadRequested])

  const runsWithDef = useMemo((): ReportRun[] => {
    const out = [] as ReportRun[]
    for (const run of recentRuns) {
      if (run.reportDefinitionID in defsLookup) {
        run.def = defsLookup[run.reportDefinitionID]
        out.push(run)
      }
    }
    return out
  }, [recentRuns, defsLookup])

  const sortedRuns = useMemo((): ReportRun[]  => {
    const out = [] as ReportRun[]
    if (!runsWithDef || runsWithDef.length === 0) {
      return out
    }
    for (let i = 0; i < runsWithDef.length; i++) {
      out.push(runsWithDef[i])
    }
    const compare = (a: ReportRun, b: ReportRun ): number => {
      if ((a.ts || 0) > (b.ts || 0)){
        return -1
      }
      if ((a.ts || 0) < (b.ts || 0)){
        return 1
      }
      return 0
    }
    out.sort(compare)
    if (out.length <= 10) {
      return out
    }
    return out.slice(0, 10)
  }, [runsWithDef])

  const rows = useMemo((): row[] => {
    const out = [] as row[]
    if (sortedRuns.length === 0) {
      return out
    }
    for (var i = 0; i < sortedRuns.length; i++) {
      if (!sortedRuns[i]) {
        continue
      }
      const r = sortedRuns[i]
      out.push({
        cells: [
          {
            value: r.def ? r.def.description || '-' : '-',
            onClick: () => { openRunModal(r) },
          },
          {
            value: ago(sortedRuns[i].ts || 0),
          },
          {
            value:  (r.def?.reportFormat || '').toUpperCase()
          },
          {
            value: runStatusLabel(sortedRuns[i]),
            className: runStatusClass(sortedRuns[i])
          },
          {
            value: !sortedRuns[i].files ? 0 : sortedRuns[i].files.length
          }
        ] as rowCell
      } as row)
    }
    return out
  }, [sortedRuns, openRunModal])

  return <div className="reports-recently-generated-list">
    <BasicTable
      cols={cols}
      rows={rows}
      isLoading={isLoading}
      noDataMessage={`${customerDN} has no reports. Press '+ New Report' to generate one.`}
    />
    {isRunModalVisible && run &&
    <ModalRunDetails
      run={run || {} as ReportRun}
      def={run && run.def ? run.def : {} as ReportDefinition}
      closer={runModalClosed}
      requestReloadFunc={requestReload}
    />}
  </div>
}